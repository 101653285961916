.App {
  background-color: #1d1d1f;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.code-highlight {
  background-color: #050607;
}

h2 {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CodeBlock {
  display: flex;
  align-items: center;
  background-color: #050607;
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 1rem;

}
.codeBlockList {
  display: flex;
  flex-direction: row

}
.img-logo {
  display: flex;
  align-items: center;
  align-self: center;
}

Link {
  text-decoration-line: none;
  text-decoration: none;
}

button {
  display: grid;
  align-items: center;
  background-color: #050607;
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 1rem;
  flex-direction: row;
}


textarea {
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px;
  width: 100%;
  height: 500px;
  border: none; /* Remove the border */
  border-radius: 4px;
  background-color: #2b2b2b;
  color: #fff;
  outline: none; /* Remove the outline when focused */
}

textarea:focus {
  box-shadow: none;
}



.clientsList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.mainWrap {
  display: flex;
  width: 100%;
  
}
.homePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.aside {
  background: #2b2b2b;
  padding: 16px;
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 80vh;
  border-radius: 0.5rem;
  margin: 1rem;
}

.editorSide {
  display: flex;
  height: 100vh;
  background-color: #2b2b2b;
  color:#050607;
  border-radius: 0.5rem;
  margin: 1rem;
  height: 80vh;
  width: 80%;
  padding: 16px;

}


.client {
  font-size: small;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.App-header {
  background-color: #050607;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
